import React from 'react';
import Carousel from '../components/Carousel';
import Destinations from '../components/Destinations';
import Footer from '../components/Footer';
import Hero from '../components/Home/Hero/Hero';
import Navbar from '../components/Navbar';
import Search from '../components/Search';
import Selects from '../components/Selects';
import { SecondDiv } from '../components/Home/SeconDiv/SecondDiv';
import  SolutionDiv  from '../components/Home/SolutionDiv/SolutionDiv';

import { MapDiv } from '../components/Home/Contact/MapDiv';

import { Helmet } from 'react-helmet';
// var days = [-10,-9,-8,-5,-3,0,1,2,3,4,5,6,7,10,13,15,16,20]
import Logoimg from '../assets/logo/alnokhatha.jpg';
import {FlipCard} from '../components/FlipCard/FlipCard'
import { Companies } from '../components/Home/Companies/Companies';

export const Home = () => {
  return (
    <div>

      <Helmet>‍
        <title>ALNOKHATHA Companies Management </title>‍
        <meta name="description" content="ALNOKHATHA Companies Management - Key Access Point for Global Business Owners & Entrepreneurs in Sharjah." />        
        <meta name="twitter:card" content={Logoimg} />        
        {/* <meta name="twitter:site" content="@user" />         */}
        <meta name="twitter:creator" content="@technokasim" />        
        <meta name="twitter:title" content="ALNOKHATHA Companies Management." />       
         <meta name="twitter:description" content="ALNOKHATHA Companies Management - Key Access Point for Global Business Owners & Entrepreneurs in Sharjah." />        
         <meta name="twitter:image" content={Logoimg}/>        
         <meta property="og:title" content="ALNOKHATHA Companies Management - Key Access Point for Global Business Owners & Entrepreneurs in Sharjah." />        
         <meta property="og:description" content="ALNOKHATHA Companies Management - Key Access Point for Global Business Owners & Entrepreneurs in Sharjah." />        
         <meta property="og:image" content={Logoimg}/>
        <meta property="og:url" content="pets.abc" />
        <meta property="og:site_name" content="ALNOKHATHA Companies Management - Key Access Point for Global Business Owners & Entrepreneurs in Sharjah." />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="ALNOKHATHA Companies Management - Key Access Point for Global Business Owners & Entrepreneurs in Sharjah." />
        {/* <meta property="fb:app_id" content="ID_APP_FACEBOOK" /> */}
      </Helmet>


      <Hero />
      <SecondDiv />
      <SolutionDiv/>
      {/* <Destinations /> */}
      {/* <Search /> */}
      <Companies />
      {/* <Carousel /> */}

      {/* <FlipCard /> */}

      <MapDiv />

    </div>
  )
}
