import Sl from '../../../assets/images/solution-background-alnokhatha.jpg';
import styled from 'styled-components';


export const SLDiv = styled.div`
background: url(${Sl});
background-size: cover;
position: relative;
color: #fff;
`

export const SLBox = styled.div`
margin-top: 63px;
margin-bottom: 63px;

@media only screen and (min-width: 601px) {
    padding: 0 7em;
  }

`

export const SLInsideBox = styled.div`
padding: 4em 2.5em;
cursor: pointer;
position: relative;
counter-increment: number;
height: 400px;
:hover {
    background: linear-gradient(to bottom,#b07e4d 0,#9b622a 50%);
    // opacity: 0.5;
    transition: .5s;
    z-index: 0;
    box-shadow: 0 0 25px rgb(0 0 0 / 50%);
}
`

export const SLTxt = styled.h1`
margin: 1.5em 0 1em;
line-height: 1.1;
font-size: 1.25em;
font-weight: 600;
position: relative;
z-index: 1;
height: 60px;
`

export const SLP = styled.p`
margin-bottom: 3em;
font-size: 0.825em;
`

export const SLSvg = styled.img`
padding: 0 8em 0 0;
color: 'white';
`

export const SDImg = styled.img`
width: ${props => (props.width ? props.width : "100%")};
height: ${props => (props.height ? props.height : "auto")};
`

export const SDRightDiv = styled.div`
content: '';
// position: absolute;
bottom: 0;
right: 0;
width: 100%;
height: 4em;
background: #b07e4d;
`
