import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import { Contact } from './pages/Contact';

import Loading from './components/Loading/Loading'


// function Loading(props) {
//   return <div>Loading...</div>;
// }

import ReactGA from "react-ga";

ReactGA.initialize("UA-186385750-2");

function App() {

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    // Wait for 3 seconds
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);
  
  // Custom css for loader
  const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;

  return (
    isLoading ?
    <Loading isLoading={isLoading}/> :
    <BrowserRouter>
    {/* <Suspense fallback={<Loading />} minDuration={20000}> */}
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} /> */}
      </Routes>
      <Footer/>
      {/* </Suspense> */}
  </BrowserRouter>
  );
}

export default App;
