import styled from 'styled-components'

import bglow from '../../../assets/svg/arrowback-white-alnokhatha.svg';




// export const Maindiv = styled.div`
// width: '100%';

// `

export const ArrowStyle = {
    display: "inline-block",
    // width: "0.875em",
    // height: "0.6875em",
    backgroundSize: "contain",
    marginLeft: "1em"
  };

export const ButtonLink = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    transition: color .3s;
    position: relative;
    display: inline-block;
    color: #fff;
    border-radius: 3.55em;
    padding: 0.673em 1.5em;
    text-align: center;
    font-size: 1.125em;
    border: none;
    min-width: 16.816em;
    -webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    box-shadow: 0 0 15px rgb(0 0 0 / 10%);
    z-index: 1;
    background: linear-gradient(to right,#b07e4d 0,#9b622a 100%);
    overflow: hidden;
 `

export const Downdiv = styled.div`
content: '';
position: absolute;
bottom: 0;
left: 0;
width: 14em;
height: 12.5em;
background: url(${bglow});
z-index: 3;
// opacity: .7;
background-size: 1.025em 1.125em;
`


export const HeroMainDiv = styled.div`
 border-left: 0.3125em solid #b07e4d;

 `


export const HeroDiv = styled.div`
//  border-left: 0.3125em solid #b07e4d;
//  padding: 0.25em 0 0.25em 5em;
 position: relative;
 max-width: 84%;
 width: 100%;
 text-align: left;
 padding-left: 20px;
 `

export const InnerDiv = styled.div`
@media only screen and (min-width: 768px) {
    padding: 0 7em;
    margin-left: 87px;
    margin-top: 154px;
}
@media only screen and (max-width: 768px) {
    padding: 10px;
}


}
 `

export const Title1 = styled.h1`
font-size: 2.875em;
font-weight: 700;
line-height: 1.1;
margin-bottom: 0.5em;
 `

export const Title2 = styled.h2`
font-size: 4.375em;
font-weight: 700;
line-height: 1.1;
margin-bottom: 0.5em;
 `