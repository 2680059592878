import styled from 'styled-components'
import bgs from '../../assets/svg/d.svg';

export const Ring = styled.div`
    position: fixed;
    width: 13em;
    height: 13em;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    -webkit-transition-duration: 100ms;
    transition-duration: 100ms;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    will-change: width, height, transform, border;
    z-index: 999;
    pointer-events: none;
    content: '';
    background: url(${bgs});
    opacity: .4;
    background-size: 1.5em 1.5em;
  `
