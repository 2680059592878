import React , { useState }from 'react'
import {SLDiv, SLBox, SLInsideBox, SLTxt, SLP, SLSvg} from './SolutionDivStyle';
import {Container, Row, Col} from 'react-bootstrap'
import { NormalText, MediumText, Button } from '../../../GlobalStyle';

import { ReactComponent as SEW } from '../../../assets/svg/economic.svg';
// import LockIcon from '../../../assets/svg/economic.svg';



import { SolutionsData } from '../../../data/SolutionsData';

export const SolutionDiv = () => {

  
    const [isShown, setIsShown] = useState(false);

    const imgstyle = {
      height: "105px"
    };
  

  return (
    <SLDiv>
        <Container>
            <Row>
                {/* <Col md={1}>
                </Col> */}
                <Col md={12}>
                <SLBox>
                <NormalText>Services</NormalText>
              <MediumText color={'white'}> One Place for All Legal Smart Solutions</MediumText>
              <Row>
              {SolutionsData.map((el, index) => (
                <Col md={3}>
                    <SLInsideBox>
                        {/* <SLSvg src={el.image}/> */}
                        {/* <SEW viewBox="0 0 67.3 67.5"  width="80" height="70" /> */}
                        <img style={imgstyle} src={el.image} />
                        <SLTxt>{el.title}</SLTxt>

                        <SLP>{el.description}</SLP>

                        {/* <Button>Get To Know More</Button> */}
                    </SLInsideBox>
                </Col>
              ))}
        
            
              </Row>
                </SLBox>
                </Col>

                {/* <Col md={1}> 
                </Col> */}
            </Row>
        </Container>
    </SLDiv>
  )
}



export default SolutionDiv