import styled from "styled-components";


export const CPdiv = styled.div`
display: flex;
align-items: center;
justify-content: center;
color: black;
// background-color: #b07e4d;
font-size: 30px;
width: 100%;
height: 200px;
border-radius: 3px;
text-align : center;
box-shadow: 6px 6px 6px 0px #ffefde;
// padding: 25px 0;
background: rgb(204,153,102);
background: linear-gradient(90deg, rgba(204,153,102,1) 0%, rgba(213,160,108,1) 28%, rgba(204,153,102,1) 100%);
`

export const CPText = styled.h1`
font-size: 1em;
font-weight: 600;

color: white;
`

export const CPIndiv = styled.div`
padding: 20px;

`