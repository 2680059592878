// import { ReactComponent as SEW } from '../../../assets/svg/economic.svg';

// import { ReactComponent as S1 } from '../../../assets/svg/economic.svg';

import svg1 from '../assets/svg/economic.svg'
import svg2 from '../assets/svg/economic.svg'
import svg3 from '../assets/svg/health-insurance.svg'
import svg4 from '../assets/svg/ministry.svg'
import shams from '../assets/svg/shams.svg'
import federal from '../assets/svg/federal.svg'
import sharjah_chamber from '../assets/svg/sharjah_chamber.svg'
import sharjah_municipality from '../assets/svg/sharjah_municipality.svg'
import tax_consultancy from '../assets/svg/tax_consultancy.svg'
import notary from '../assets/svg/notary.svg'
import tasheel from '../assets/svg/tasheel.svg'


export const SolutionsData = [
	{
		title: 'Sharjah Electricity And Water Authority',
		description:'Enjoy convenient and reliable bill payment service...',
        image: svg1,
	},
	{
		title: 'Sharjah Economic Development Department',
		description:'The Sharjah Department of Economic Development cor...',
		image: svg2,

	},
	{
		title: 'Health Insurance',
		description:'Al Buhaira National Insurance Company (ABNIC) is a...',
		image: svg3,
	},
	{
		title: 'Ministry of Interior',
		description:'Sharjah Police is committed to provide a wide rang...',
        image: svg4,
	},
	{
		title: 'Sharjah Media City (Shams)',
		description:'Sharjah Media City (Shams) is a world-class media ...',
        image: shams,
	},
	{
		title: 'Federal Authority for Identity & Citizenship',
		description:'Federal Authority for Identity and Citizenship (IC...',
        image: federal,
	},
	{
		title: 'Sharjah Chamber of Commerce & Industry',
		description:'SCCI participates in the organization of economic ...',
        image: sharjah_chamber,
	},
	{
		title: 'Sharjah City Municipality',
		description:'Sharjah City Municipality is one of the oldest mun...',
        image: sharjah_municipality,
	},
	{
		title: 'Tax Consultancy',
		description:'we are offering tax services to help our customers...',
        image: tax_consultancy,
	},
	{
		title: 'Notary Public',
		description:'we provide you with services related to certifyin...',
        image: notary,
	},
	{
		title: 'TAS-HEEL',
		description:'TAS-HEEL service centers represent the partnership...',
        image: tasheel,
	},
	{
		title: 'Case Registration',
		description:'This service allows customers to file before feder...',
        image: notary,
	},


];

