import SD from '../../../assets/images/second-div-bg.jpg';
import styled from 'styled-components';


export const SDBg = styled.div`
background: url(${SD});
backgroundSize: '100% 68%';
height: 100%;
position: relative;
padding: 7em 0 0;

`

export const SDBox = styled.div`
padding: 0 0 0 4em;
`

export const SDBox2 = styled.div`


@media only screen and (min-width: 601px) {
        padding: 7em 7em 0 7em;
  
  }
`

export const SDDivBox = styled.div`
padding: 0 8em 0 0;
`

export const SDImg = styled.img`
width: ${props => (props.width ? props.width : "100%")};
height: ${props => (props.height ? props.height : "auto")};
`

export const SDRightDiv = styled.div`
content: '';
// position: absolute;
bottom: 0;
right: 0;
width: 100%;
height: 4em;
background: #b07e4d;
`




