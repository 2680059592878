import styled from 'styled-components';
import bgs from './assets/svg/dark-dots.svg';


export const NormalText = styled.h2`
    font-size: 20px;
    color: ${props => (props.color ? props.color : "#b07e4d")};
    position: relative;
    display: inline-block;
    padding-left: 3.375em;
    margin-bottom: 0.75em;
    margin-left: -3.375em;
`

export const MediumText = styled.h1`
color: #242935;
font-size: 2.75em;
font-weight: 700;
line-height: 1.1;
margin-bottom: 0.53em;
color: ${props => (props.color ? props.color : "black")};

`

export const Dotted = styled.div`
background: url(${bgs});
content: '';
// position: absolute;
bottom: 0;
right: 0;
width: ${props => (props.width ? props.width : "100%")};
height: ${props => (props.height ? props.height : "22em")};
// width: 100%;
// height: 22em;
// background: url(../../../themes/saeed-theme/assets/svg/dark-dots.svg);
background-size: 1.5em 1.5em;
// z-index: -1;
opacity: .2;
`

export const Button = styled.div`
transition: color .3s;
position: relative;
display: inline-block;
color: #fff;
border-radius: 3.55em;
padding: 0.673em 1.5em;
text-align: center;
font-size: 0.725em;
border: none;
min-width: 16.816em;
-webkit-backface-visibility: hidden;
-moz-osx-font-smoothing: grayscale;
box-shadow: 0 0 15px rgb(0 0 0 / 10%);
z-index: 1;
background: linear-gradient(to right,#b07e4d 0,#9b622a 100%);
overflow: hidden;
`


