import React from 'react'
import SD from '../../../assets/images/second-div-bg.jpg';
import { Container, Row, Col  } from 'react-bootstrap'
import { SDBg, SDBox, SDBox2, SDImg, SDDivBox, SDRightDiv } from './SDStyle';
import { NormalText, MediumText, Dotted } from '../../../GlobalStyle';

import AB1 from '../../../assets/images/mission-picture-for-home-page-and-about-us-alnokhatha.jpg';
import AB2 from '../../../assets/images/mission-picture-for-home-page-and-about-us-alnokhatha2.jpg';


export const SecondDiv = () => {
  return (
    <>
<Container fluid id="about">
      <Row>
        <Col md={1}>
        </Col>
        <Col md={10}>
          <SDBg>
            <SDBox>
                <NormalText>
              About ALNOKHATHA Companies Management
              </NormalText>
              <MediumText>
                    Key Access Point for Global Business Owners & Entrepreneurs in Sharjah
              </MediumText>
              <Row>
                <Col md={6}>
                  <SDDivBox>
                    <NormalText color={'black'}>
                      OUR MISSION
                    </NormalText>
                    <p>To provide the best services and investment opportunities to new business establishments built on Sharjah’s competitive advantages.</p>
                    <SDImg loading="lazy" src={AB1}></SDImg>
                  </SDDivBox>
                </Col>
                <Col md={6} style={{marginTop:'20px'}}>
                <SDDivBox>
                      <NormalText color={'black'}>OUR VISION</NormalText>
                    <p>To provide the best services and investment opportunities to new business establishments built on Sharjah’s competitive advantages.</p>
                    <SDImg loading="lazy" src={AB2}></SDImg> 
                </SDDivBox>

                </Col>
              
              </Row>
            </SDBox>
          </SDBg>
        </Col>
        <Col md={1}>
        <Dotted />
  

        </Col>
      </Row>
</Container>

<Container fluid>

  <Row>
                {/* <Col md={1}></Col> */}
                <Col md={8}>
                <SDBox2>

                <NormalText>High-End & Advanced</NormalText>
                <MediumText>
                    Fully-integrated & Streamlined Services to Investors
                </MediumText>
                    <SDDivBox>
                    <p>Our legal support and advisory framework cater to high-growth industries, businesses and investment prospects. Discover potential opportunities supported by the central and local government.</p>
                    </SDDivBox>
                </SDBox2>

                </Col>
                <Col md={4}>
                  <div style={{paddingTop:'22px'}}>
                  <Dotted/>

                  </div>
                </Col>

              
              </Row>
              <Row>
              <Col md={8}>

                </Col>
                <Col md={4}>
                  <SDRightDiv />
                </Col>
              
              </Row>
         
    
</Container>
    </>
  )
}
