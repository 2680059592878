import React from 'react';
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaPinterest,
  FaYoutube,
} from 'react-icons/fa';

import { Link } from 'react-router-dom';
const Footer = () => {

  const linkstyle={
    textDecoration:'none',
    color:'black'
  }
  return (
    <div className='w-full bg-gray-100 py-16'>
      <div className='max-w-[1240px] mx-auto flex flex-col px-4'>
        <div className='sm:flex text-center justify-between items-center'>
          <h1>ALNOKHATHA.AE</h1>
          <div className='flex justify-between w-full sm:max-w-[280px] my-4'>
           <a target="_blank" href="https://www.facebook.com/Alnokhatha-Companies-Management-113246464693170" > <FaFacebook className='icon' />  </a>
            {/* <FaTwitter className='icon' /> */}
           {/* <a target="_blank" href=""> <FaYoutube className='icon' /> </a> */}
            {/* <FaPinterest className='icon' /> */}
           <a target="_blank" href="https://www.instagram.com/alnokhatha.group/"> <FaInstagram className='icon' /> </a>
           <a target="_blank" href="https://twitter.com/Alnokhatha_c_m"> <FaTwitter className='icon' /> </a>
          </div>
        </div>
        <div className='flex justify-between'>
          <ul className='lg:flex'>
            {/* <li>NSS</li>
            <li>KTT</li>
            <li>A2Z</li> */}
          </ul>
          <ul className='text-right lg:flex'>
            <li> <a href="#home" style={linkstyle}>Home</a></li>
            <li><a href="#cp" style={linkstyle}>COMPANIES</a></li>
            <li> <a href="#about" style={linkstyle}>ABOUT</a></li>
            <li> <a href="#contact" style={linkstyle}>CONTACT</a></li>
            {/* <li>Book</li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
