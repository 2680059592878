import React from 'react'
import { Container, Row, Col  } from 'react-bootstrap'
import { NormalText, MediumText } from '../../../GlobalStyle'

export const MapDiv = () => {

const bgstyle = {
    background: "#eceeef"
  }

const rowstyle = {
    paddingTop:'25px',
    paddingBottom:'25px'
  }
  return (
    <Container id="map" fluid style={bgstyle}>
      <Row style={rowstyle}>
        <Col md={1}>

        </Col>
        <Col md={10}>
            <Row>
              <Col md={6}>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115400.59297051685!2d55.44355604857046!3d25.328768680015152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef5f3cd77f47d55%3A0x21482cf4d894e0a2!2sALNOKHATHA%20Companies%20Management!5e0!3m2!1sen!2sae!4v1660553970174!5m2!1sen!2sae" width="100%" height="350px"></iframe>

              </Col>
              <Col md={6}>
              <NormalText>Press Releases </NormalText>
              <MediumText>
              Headquarters: <br />
              Emirates Industrial / Sharjah
              </MediumText>

              <NormalText color={'black'}>
                      Whatsapp : <br /> 0 56 380 0082
                    </NormalText> <br />
              <NormalText color={'black'}>
                      Email us at : <br /> info@alnokhatha.ae
                    </NormalText>

              </Col>
            </Row>
        </Col>
        <Col md={1}>

        </Col>
      </Row>
    </Container>
  )
}
