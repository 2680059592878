import React from 'react';
import './style.scss'

import BoraBora from '../../assets/logo/alnokhatha.jpg';
import BoraBora2 from '../../assets/logo/alnokhatha-security-solutions.jpg';
import Maldives from '../../assets/logo/al-khidhma-al-thabia-typing.jpg';
import Maldives2 from '../../assets/logo/A2Z-HR-MANAGEMENT.jpg';
import Maldives3 from '../../assets/maldives3.jpg';
import KeyWest from '../../assets/keywest.jpg';

import { Container, Row, Col } from 'react-bootstrap';


export class FlipCard extends React.Component {
    render() {
      return (
      <Container>
        <Row>
          <Col md={4}>
              <Front />
          </Col>
          <Col md={4}>
          <Front />

          </Col>
          <Col md={4}>

          </Col>
        </Row>

      </Container>
      )
    }
  }
  
  class BlogCard extends React.Component {
    constructor(props) {
      super(props);
      this.state = { flipped: false };
      this.flip = this.flip.bind(this);
    }
  
    flip = () => {
      this.setState({ flipped: !this.state.flipped });
    }
    render() {
      return (
  
  
        <div onMouseEnter={this.flip} onMouseLeave={this.flip} className={"card-container" + (this.state.flipped ? " flipped" : "")}>
  
          <Front />
          {/* <Back /> */}
        </div>
  
      )
    }
  }
  
  class Front extends React.Component {
    render() {
      return (
        <>
        <div className="front">
          {/* <ImageArea /> */}
          {/* <MainArea /> */}

          <div className="back">
          <p>Some sample text to demonstrate how these cards will work, including how they truncate long sentences. This section displays the full-length blog post.</p>
          <p>Bloggity bloggity bloggity blog. This would be the full text of the abbreviated blog post.</p>
        </div>
   
        <div className="image-container">
          <img className="card-image" src={BoraBora}></img>
          <h1 className="title">An example blog post</h1>
        </div>
        </div>
        <div className="back">
        <p>Some sample text to demonstrate how these cards will work, including how they truncate long sentences. This section displays the full-length blog post.</p>
        <p>Bloggity bloggity bloggity blog. This would be the full text of the abbreviated blog post.</p>
        </div>
</>
      )
    }
  }
  
  class Back extends React.Component {
    render() {
      return (
        <div className="back">
          <p>Some sample text to demonstrate how these cards will work, including how they truncate long sentences. This section displays the full-length blog post.</p>
          <p>Bloggity bloggity bloggity blog. This would be the full text of the abbreviated blog post.</p>
        </div>
      )
    }
  }
  
  class ImageArea extends React.Component {
    render() {
      return (
        <>
        <div className="back">
          <p>Some sample text to demonstrate how these cards will work, including how they truncate long sentences. This section displays the full-length blog post.</p>
          <p>Bloggity bloggity bloggity blog. This would be the full text of the abbreviated blog post.</p>
        </div>
   
        <div className="image-container">
          <img className="card-image" src={BoraBora}></img>
          <h1 className="title">An example blog post</h1>
        </div>
        </>
      )
    }
  
  }
  
  class MainArea extends React.Component {
    render() {
      return (
        <div className="main-area">
          <div className="blog-post">
            <p className="date">{new Date().toLocaleDateString()}</p>
            <p className="blog-content">
              Some sample text to demonstrate how these cards will work, including how they truncate long sentences.
              </p>
            <p className="read-more">Hover to read more...</p>
  
          </div>
  
        </div>
      )
    }
  }
  