import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import beachVid from '../../../assets/sharjah.mp4';
import { Downdiv, HeroDiv, Title1, InnerDiv, HeroMainDiv, ArrowStyle } from './HeroStyle';
// import bgs from '../../assets/svg/dots.svg';
import DotRing from "../../DotRing/DotRing";
import { ButtonLink } from './HeroStyle';

import { BsArrowRight } from "react-icons/bs";

import {Link} from 'react-router-dom';

const Hero = () => {

      const mystyle = {
        color: "white",
        backgroundColor: "DodgerBlue",
        padding: "10px",
        fontFamily: "Arial"
      };
  return (
    <div id="home" className='w-full h-screen relative'>
      {/* <DotRing /> */}
      <video loading="lazy"
        className='w-full h-full object-cover'
        src={beachVid}
        autoPlay
        loop
        muted
      />
      <div className='absolute w-full h-full top-0 left-0 bg-gray-900/30'></div>
      <div className='absolute top-0 w-full h-full flex flex-col justify-center text-center text-white p-4'>
        <InnerDiv>
        
        <HeroMainDiv>
          <HeroDiv>
           {/* <bgs /> */}
          <Title1>
            Welcome To ALNOKHATHA
            <br />
            Group Of Companies
          </Title1>
          <a href="#map"><ButtonLink> Get to Know More<BsArrowRight style={ArrowStyle} /> </ButtonLink></a>
          </HeroDiv>
        </HeroMainDiv>
        </InnerDiv>

        {/* <form
          className='flex justify-between items-center max-w-[700px] mx-auto w-full border p-1
          rounded-md text-black bg-gray-100/90'
        >
          <div>
            <input
              className='bg-transparent w-[300px] sm:w-[400px] font-[Poppins] focus:outline-none
                  '
              type='text'
              placeholder='Search Destinations'
            />
          </div>
          <div>
            <button>
              <AiOutlineSearch size={20} className='icon' style={{color: '#ffffff'}}  />
            </button>
          </div>
        </form> */}

        <Downdiv />
      </div>
    

    </div>
  );
};

export default Hero;
