import React , { useState }from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import { CPdiv, CPIndiv, CPText } from './CompanyStyle'
import { NormalText, MediumText, Button } from '../../../GlobalStyle';



export const Companies = () => {
  return (
    <Container id="cp" style={{marginTop:'25px', marginBottom: '25px'}}>
        <MediumText>We Together In The Journey</MediumText>
        <Row>
            <Col md={4}> 
                <CPIndiv>
                    <CPdiv>
                            <CPText>Companies Management </CPText>
                    </CPdiv>
                </CPIndiv>
            </Col>
            <Col md={4}> 
            <a href='https://cctvuae.ae/' style={{textDecoration:'none'}}>
                <CPIndiv>
                    <CPdiv>
                            <CPText>Security Solutions</CPText>
                    </CPdiv>
                </CPIndiv>
                </a>
            </Col>
            <Col md={4}> 
            <a href='https://typingvisa.com/' style={{textDecoration:'none'}}>
                <CPIndiv>
                    <CPdiv>
                            <CPText>Typing Services </CPText>
                    </CPdiv>
                </CPIndiv>
            </a>

            </Col>
            <Col md={4}> 
                <CPIndiv>
                    <CPdiv>
                            <CPText>HR Services </CPText>
                    </CPdiv>
                </CPIndiv>
            </Col>
            <Col md={4}> 
                <CPIndiv>
                    <CPdiv>
                            <CPText>Real Estate </CPText>
                    </CPdiv>
                </CPIndiv>
            </Col>
            <Col md={4}> 
                <CPIndiv>
                    <CPdiv>
                            <CPText>Investment</CPText>
                    </CPdiv>
                </CPIndiv>
            </Col>
   
     
        </Row>
    </Container>
  )
}
