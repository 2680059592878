import * as React from "react";
import "./styles.css";

// import mainlogo from '../../assets/svg/logo.svg';
import mainlogo from '../../assets/svg/2.png';


export default function Loading() {
  return (
    // <div id="section-preloader">

    //   <div className="boxes">
    //     <div className="box">
    //       <div />
    //       <div />
    //       <div />
    //       <div />
    //     </div>
    //     <div className="box">
    //       <div />
    //       <div />
    //       <div />
    //       <div />
    //     </div>
    //     <div className="box">
    //       <div />
    //       <div />
    //       <div />
    //       <div />
    //     </div>
    //     <div className="box">
    //       <div />
    //       <div />
    //       <div />
    //       <div />
    //     </div>
    //   </div>
    //   <p>ALNOKHATHA...</p>

    //   {/* <div  className="boxes"> */}
    //     {/* <img src={mainlogo} /> */}
    //   {/* </div> */}
    // </div>
<div className="background">

    <div className="loader">

        <img src={mainlogo} />

    </div>
</div>

  );
}
